<template>
  <Dialog
    v-model:visible="displayModal"
    :modal="true" :closeOnEscape="true"
    :dismissableMask="true"
    :breakpoints="{'1300px': '100vw'}" :style="{width: '75vw'}"
  >
    <template #header>
      <div style="color:#1c3faa;">
        <h4>
          <i class="pi pi-info-circle" style="font-size: 1.5rem;margin-right:10px"></i>
          <strong style="font-size:20px">{{infoModal.medicamento.ItemCode}} - {{infoModal.medicamento.ItemName}}</strong>
        </h4>
      </div>
    </template>
    <div>
      <div class="font-bold">Cartas de agotado</div>
      <div>
        <DataTable
          :value="infoModal.cartas"
          class="p-datatable-sm text-xxs"
          showGridlines
          dataKey="nro_carta"
          responsiveLayout="scroll"
        >
          <Column
            field="nro_carta"
            header="Nro Carta"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" />
          <Column
            field="fecha_carta"
            header="Fecha carta"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" />
          <Column
            field="laboratorio"
            header="Laboratorio"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" />
          <Column
            field="fecha_disponible"
            header="Fecha Disponible"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" >
            <template #body="{data}">
              <div>{{data.fecha_disponible ?? 'Por Confirmar'}}</div>
            </template>
          </Column>
          <Column
            field="estado"
            header="Estado"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" />
          <Column
            field="estado"
            header="Estado"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center" >
            <template #body="{data}">
              <div>
                <Button
                  type="button"
                  class="p-button-xs p-button-danger p-button-text"
                  @click="showPdfCarta(data.nro_carta)"
                >
                  <i class="pi pi-file-pdf"></i>
                </Button>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { ref } from 'vue'
import axios from 'axios'

export default {
  name: 'modalCartasAgotadoMx',
  setup () {
    const displayModal = ref(false)
    const infoModal = ref({})
    const openModalCarta = (data) => {
      infoModal.value.medicamento = data.medicamento
      infoModal.value.cartas = data.cartas
      displayModal.value = true
    }
    const showPdfCarta = (nroCarta) => {
      const url = `${process.env.VUE_APP_MICROSERVICE_API_CARTA_AGOTADO_COMPRAS}/download/downloadCarta/${nroCarta}`
      const res = axios.getUri({ url })
      window.open(res, '_blank')
    }
    return { displayModal, infoModal, openModalCarta, showPdfCarta }
  }
}
</script>

<style scoped>

</style>
